<template>
  <div class="main-wrapper" data-app>
    <!-- Banner -->
    <header-two v-if="showBanners" :items="banners" />

    <div class="container">
      <v-breadcrumbs
        style="padding: 0px; color: black"
        :items="navRoutes"
        divider=">"
      ></v-breadcrumbs>
    </div>

    <!-- Content -->
    <div class="content" style="padding-top: 0px">
      <div class="container">
        <!-- Blog Posts -->
        <div class="blog-page">
          <div class="row">
            <!-- Post Content -->
            <div class="col-lg-9 col-md-8 padding-right-30">
              <!-- Blog Post -->
              <div class="blog-post single-post">
                <!-- loading message -->
                <div v-if="loading" style="margin-top: 10%">
                  <div class="row m-0">
                    <v-progress-circular
                      style="margin: auto"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                  <div class="row m-0">
                    <h4 style="margin: auto">
                      {{ message }}
                    </h4>
                  </div>
                </div>
                <!-- Content -->
                <div class="post-content">
                  <countries-flags
                    :countries="
                      publication.countries && publication.countries.length > 0
                        ? publication.countries
                        : []
                    "
                  ></countries-flags>

                  <h1 style="font-size: 1.8rem">{{ publication.title }}</h1>
                  <ul class="post-meta my-2" v-if="publication.type !== 'events'">
                    <li v-if="publication.date">
                      {{
                        new Date(publication.date).toLocaleDateString('es-VE', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })
                      }}
                    </li>
                    <li>
                      {{ publication.author }}
                    </li>
                  </ul>
                  <!-- Img -->
                  <div
                    style="display: flex; justify-content: center; align-items: center"
                  >
                    <img
                      class="post-img img-fluid my-3"
                      style="width: 70%"
                      v-if="publication.cover && publication.cover.route"
                      :src="publication.cover.route"
                      :alt="publication.title"
                    />
                  </div>
                  <v-card-text v-if="publication.type === 'events'">
                    <span v-if="publication.address"
                      ><strong>Ubicación:</strong> {{ publication.address }}</span
                    ><br v-if="publication.address" />
                    <span v-if="publication.dateRange > 0 && publication.startAt"
                      ><strong>Inicia:</strong> {{ publication.startAt }}</span
                    ><br v-if="publication.dateRange > 0 && publication.startAt" />
                    <span v-if="publication.dateRange > 0 && publication.expired_at"
                      ><strong>Culmina:</strong> {{ publication.endAt }}</span
                    ><br v-if="publication.dateRange > 0 && publication.expired_at" />
                    <span v-if="publication.dateRange == 0"
                      ><strong>Fecha:</strong>
                      {{ publication.startAt + ' . Único día' }}</span
                    ><br v-if="publication.dateRange == 0" />
                    <span v-if="publication.modality"
                      ><strong>Modalidad:</strong> {{ publication.modalityText }}</span
                    >
                  </v-card-text>

                  <div class="post-text text-box" v-html="publication.content"></div>
                </div>
              </div>
              <!-- Blog Post / End -->

              <!-- Call to Action Buttom -->
              <!-- v-if="this.publication.cta_link && this.publication.cta_text" -->
              <a
                class="cta__button"
                :href="publication.cta_link"
                target="_blank"
                rel="noopener"
                v-if="
                  publication.cta_link &&
                  publication.cta_link !== '' &&
                  publication.cta_text &&
                  publication.cta_text !== ''
                "
                ><v-icon dark style="color: #003a56"> mdi-cursor-default-click </v-icon>

                {{ publication.cta_text }}
              </a>
            </div>

            <!-- Content / End -->
            <!-- Widgets -->
            <div class="col-lg-3 col-md-4">
              <div class="sidebar right">
                <!-- Widget -->
                <div class="widget margin-top-40">
                  <h3>Más populares</h3>
                  <ul class="widget-tabs">
                    <!-- Posts -->
                    <li v-for="post in items.slice(0, 5)" :key="post.id">
                      <div class="widget-content">
                        <div class="widget-thumb">
                          <a :href="'/publicaciones/' + post.slug">
                            <img alt="" class="img-fluid" :src="post.cover.route" />
                          </a>
                        </div>
                        <div class="widget-text">
                          <a :href="'/publicaciones/' + post.slug"
                            ><h5 class="text-primary">
                              {{ post.title }}
                            </h5></a
                          >
                          <span>{{
                            new Date(post.date).toLocaleDateString('es-VE', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })
                          }}</span>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- Widget / End-->
                <!-- Widget -->
                <!-- Widget / End-->
                <div class="clearfix"></div>
                <div class="margin-bottom-40"></div>
                <ads-section-3 :ads="v2Ads" style="padding: 0px"></ads-section-3>
                <social></social>
              </div>
            </div>
          </div>
          <!-- Sidebar / End -->
        </div>
      </div>
      <Whatsapp />
    </div>
  </div>
</template>

<script>
  import Header2 from 'Components/globalFrontendComponents/Header2'
  import AdsSection2 from 'Components/globalFrontendComponents/AdsSection2'
  import AdsSection3 from 'Components/globalFrontendComponents/AdsSection3'
  import Social from 'Components/globalFrontendComponents/Social'
  import CountriesFlags from 'Components/globalFrontendComponents/CountriesFlags'
  import Whatsapp from '@/components/globalFrontendComponents/WhatsApp'

  export default {
    name: 'PublicationDetail',
    props: ['country'],

    data() {
      return {
        date: new Date().toISOString(),
        intervalFunction: '',
        loading: true,
        banners: [
          {
            route:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/37dfb40cabdf76acb9bfd0330d271ba6.jpg',
          },
          {
            route:
              'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/8533cfd5263a1c8ba66ba77a7a883670.jpg',
          },
        ],
        showBanners: true,
        message: 'Cargando',
        firstTag: '',
        name: '',
        h1Ads: [],
        search: '',
        h1Ads: [],
        h2Ads: [],
        v1Ads: [],
        v2Ads: [],
        page: 1,
        pages: 1,
        items: [],
        categoryId: { id: 0, name: 'Todas' },
        categories: [{ id: 0, name: 'Todas' }],
        totalItems: 1,
        itemsPerPage: 20,
        defaultLinks: [
          {
            text: 'Inicio',
            disabled: false,
            href: '/',
          },
          {
            text: 'Franquicias',
            disabled: false,
            href: '/',
          },
          {
            text: 'Franquicia',
            disabled: true,
            href: '',
          },
        ],
        horizontal1Ads: {
          limit: 3,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        horizontal2Ads: {
          limit: 1,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        vertical1Ads: {
          limit: 1,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        vertical2Ads: {
          limit: 1,
          outstanding: 1,
          countryId: this.country ? this.country.id : 235,
        },
        navRoutes: [
          {
            text: 'Inicio',
            disabled: false,
            href: '/',
          },
          {
            text: '',
            disabled: false,
            href: '',
          },
          {
            text: '',
            disabled: true,
            href: '',
          },
        ],
        publication: {},
        publicationType: 'blogs',
        datas: [],
        dataAssigned: [],
        dataKeys: [],
        arrayOfData: [],
        selected: 1,
        typeDatas: [
          { id: 10, name: 'ACTIVIDADES' },
          { id: 14, name: 'CONTRATACIONES' },
          { id: 2, name: 'DATOS DE LA EMPRESA FRANQUICIANTE' },
          { id: 3, name: 'DATOS OPERATIVOS' },
          { id: 11, name: 'EXPERIENCIA' },
          { id: 4, name: 'INFORMACIÓN ECONÓMICA' },
          { id: 13, name: 'INFORMACION GENERAL' },
          { id: 9, name: 'INFORMACIÓN SOBRE UBICACIÓN Y LOCAL REQUERIDO' },
          { id: 1, name: 'OBJETIVO' },
          { id: 5, name: 'OTROS DATOS IMPORTANTES' },
          { id: 15, name: 'PRODUCTOS O SERVICIOS QUE OFRECEN' },
          { id: 6, name: 'RESEÑA' },
          { id: 12, name: 'SERVICIOS ESPECIALIZADOS' },
          { id: 8, name: 'TIPO DE FRANQUICIAS QUE OFRECEN' },
          { id: 7, name: 'UBICACIÓN Y DATOS DE CONTACTO' },
        ],
      }
    },
    components: {
      headerTwo: Header2,
      AdsSection3,
      AdsSection2,
      Social,
      CountriesFlags,
      Whatsapp,
    },
    watch: {
      country(newValue, oldValue) {
        this.horizontal1Ads.countryId = newValue.id
        this.horizontal2Ads.countryId = newValue.id
        this.vertical1Ads.countryId = newValue.id
        this.vertical2Ads.countryId = newValue.id
        this.getAdvertisements()
      },
    },
    computed: {
      countryId() {
        if (localStorage.getItem('selectedCountry')) {
          return JSON.parse(localStorage.getItem('selectedCountry')).id
        } else {
          return ''
        }
      },
    },
    created() {
      this.getAdvertisements()
      this.getPublication()
    },
    mounted() {
      this.adsTimer()
    },
    methods: {
      async getPublication() {
        this.loading = true
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'publication-by-slug/' +
              this.$route.params.slug
          )
          .then((response) => {
            this.publication = response.data

            switch (this.publication.type) {
              case 'blogs':
                this.navRoutes[1].text = 'Blog'
                this.navRoutes[1].href = '/blog'
                break
              case 'news':
                this.navRoutes[1].text = 'Noticias'
                this.navRoutes[1].href = '/noticias'
                break
              case 'events':
                this.navRoutes[1].text = 'Eventos'
                this.navRoutes[1].href = '/eventos'
                break

              default:
                return ''
            }

            this.navRoutes[2].text = this.publication.title

            if (this.publication.uploads) {
              this.publication.cover = this.publication.uploads
                .filter((file) => file.type == 'cover')
                .shift()
            } else {
              this.publication.images = false
            }
            this.loading = false
            if (this.publication) {
              this.message = 'Esta información no está disponible actualmente'
            }
            this.publicationType = this.publication.type
            if (this.publication.type === 'events') {
              this.publication.startAt = new Date(
                this.publication.date
              ).toLocaleDateString('es-VE', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
              this.publication.endAt = new Date(
                this.publication.expired_at
              ).toLocaleDateString('es-VE', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
              this.publication.dateRange = Math.ceil(
                Math.abs(
                  new Date(this.publication.date) - new Date(this.publication.expired_at)
                ) /
                  (1000 * 60 * 60 * 24)
              )
              switch (this.publication.modality) {
                case 'site':
                  this.publication.modalityText = 'Presencial'
                  break
                case 'remote':
                  this.publication.modalityText = 'Virtual'
                  break
                case 'remote-site':
                  this.publication.modalityText = 'Virtual - Presencial'
                  break
                default:
                  this.publication.modalityText = 'N/A'
                  break
              }
            }
            this.getPublications()
            this.loading = false
          })
      },

      adsTimer() {
        this.intervalFunction = setInterval(this.getAdvertisements, 60000)
      },
      async getAdvertisements() {
        const h1LimitStr = this.horizontal1Ads
          ? '&horizontal1[limit]=' + this.horizontal1Ads.limit
          : '&horizontal1[limit]=0'
        const h1CtryStr = this.horizontal1Ads
          ? '&horizontal1[country_id]=' + this.horizontal1Ads.countryId
          : '&horizontal1[country_id]=235'
        const h1OutStr = this.horizontal1Ads
          ? '&horizontal1[outstanding]=' + this.horizontal1Ads.outstanding
          : '&horizontal1[outstanding]=0'
        const h2LimitStr = this.horizontal2Ads
          ? '&horizontal2[limit]=' + this.horizontal2Ads.limit
          : '&horizontal2[limit]=0'
        const h2CtryStr = this.horizontal2Ads
          ? '&horizontal2[country_id]=' + this.horizontal2Ads.countryId
          : '&horizontal2[country_id]=235'
        const h2OutStr = this.horizontal2Ads
          ? '&horizontal2[outstanding]=' + this.horizontal2Ads.outstanding
          : '&horizontal2[outstanding]=0'
        const v1LimitStr = this.vertical1Ads
          ? '&vertical1[limit]=' + this.vertical1Ads.limit
          : '&vertical1[limit]=0'
        const v1CtryStr = this.vertical1Ads
          ? '&vertical1[country_id]=' + this.vertical1Ads.countryId
          : '&vertical1[country_id]=235'
        const v1OutStr = this.vertical1Ads
          ? '&vertical1[outstanding]=' + this.vertical1Ads.outstanding
          : '&vertical1[outstanding]=0'
        const v2LimitStr = this.vertical2Ads
          ? '&vertical2[limit]=' + this.vertical2Ads.limit
          : '&vertical2[limit]=0'
        const v2CtryStr = this.vertical2Ads
          ? '&vertical2[country_id]=' + this.vertical2Ads.countryId
          : '&vertical2[country_id]=235'
        const v2OutStr = this.vertical2Ads
          ? '&vertical2[outstanding]=' + this.vertical2Ads.outstanding
          : '&vertical2[outstanding]=0'

        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'advertisement-management/get-advertisements?' +
              h1LimitStr +
              h1CtryStr +
              h1OutStr +
              h2LimitStr +
              h2CtryStr +
              h2OutStr +
              v1LimitStr +
              v1CtryStr +
              v1OutStr +
              v2LimitStr +
              v2CtryStr +
              v2OutStr
          )
          .then((response) => {
            this.showBanners = false
            this.h1Ads = response.data.horizontal1
            if (this.type != 1) {
              this.h1Ads.forEach((element) => {
                this.banners.push(element.image)
              })
            }
            this.h2Ads = response.data.horizontal2
            this.v1Ads = response.data.vertical1
            this.v2Ads = response.data.vertical2
            this.showBanners = true

            //Verifying p tags content

            // console.log('asdfadsf')
            let pTags = document.getElementsByTagName('p')
            let pTagsArray = Array.from(pTags)
            // console.log(pTagsArray)
            pTagsArray.forEach((item) => {
              if (!item.textContent || textContent == '&nbsp;') {
                item.style.marginBlockStart = '0px'
                item.style.marginBlockEnd = '0px'
              }
              console.log(pTagsArray[0])
            })
          })
          .catch((error) => {})
      },
      async getPublications() {
        this.message = 'Buscando'
        this.loading = true
        let searchStr = ''
        let categoryStr = ''
        searchStr = this.search != '' ? '&search=' + this.search : ''

        categoryStr = this.categoryId.id != 0 ? '&category_id=' + this.categoryId.id : ''
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'publications?active=1&page=' +
              this.page +
              '&type=' +
              this.publicationType +
              searchStr +
              categoryStr
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.message = 'No se encontraron resultados para esta búsqueda'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            this.pages = response.data.last_page
            this.loading = false
          })
      },
      extractContent(html) {
        return new DOMParser().parseFromString(html, 'text/html').documentElement
          .textContent
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalFunction)
    },

    metaInfo() {
      return {
        title: `${this.publication.title} - XPOFranchise`,
        meta: [
          {
            name: 'description',
            content: this.extractContent(this.publication.content).slice(0, 150),
          },
          {
            property: 'og:title',
            content: `${this.publication.title} - XPOFranchise`,
          },
          {
            property: 'og:description',
            content:
              'Te invitamos a conocer el directorio que recopila la más amplia información de franquicias y afines, siendo la guía mas completa, actualizada y moderna de Iberoamérica.',
          },
          {
            property: 'og:image',
            content:
              'https://xpofranchise.comhttps://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/d3700893b85a68ba4f6877ce9102962b.svg',
          },
          {
            property: 'og:url',
            content: process.env.VUE_APP_WEBSITE + this.$route.fullPath,
          },
          {
            property: 'og:updated_time',
            content: this.publication.updated_at,
          },
          {
            name: 'twitter:title',
            content: `${this.publication.title} - XPOFranchise`,
          },
          {
            name: 'twitter:description',
            content:
              'Te invitamos a conocer el directorio que recopila la más amplia información de franquicias y afines, siendo la guía mas completa, actualizada y moderna de Iberoamérica.',
          },
          {
            name: 'twitter:image',
            content:
              'https://xpofranchise.comhttps://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/d3700893b85a68ba4f6877ce9102962b.svg',
          },
          {
            name: 'twitter:url',
            content: process.env.VUE_APP_WEBSITE + this.$route.fullPath,
          },
          {
            name: 'og:updated_time',
            content: this.publication.updated_at,
          },
        ],
      }
    },
  }
</script>

<style lang="scss">
  // .post-text p > a {
  //   color: #003a56;
  // }
  .v-card__text {
    padding-left: 0;
  }
  .active {
    background-color: #fff;
    color: #000;
  }
  .cta__button {
    display: table;
    margin: 1% auto !important;
    padding: 5px;
    min-width: 20%;
    font-size: 1.1rem;
    box-shadow: 0 4px 6px rgb(50 50 93 / 20%), 0 1px 3px rgb(0 0 0 / 15%);
  }
  .cta__button:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  .nav-item:not(:last-child) {
    padding-right: 0px;
  }
  .content__country-flag {
    margin: -2% 0 0 2%;
  }
  .content__country-flag .row {
    justify-content: flex-end;
  }
  @import '@/assets/scss/custom-styles/vue2editor.scss';
</style>
