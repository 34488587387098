var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-wrapper",attrs:{"data-app":""}},[(_vm.showBanners)?_c('header-two',{attrs:{"items":_vm.banners}}):_vm._e(),_c('div',{staticClass:"container"},[_c('v-breadcrumbs',{staticStyle:{"padding":"0px","color":"black"},attrs:{"items":_vm.navRoutes,"divider":">"}})],1),_c('div',{staticClass:"content",staticStyle:{"padding-top":"0px"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"blog-page"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9 col-md-8 padding-right-30"},[_c('div',{staticClass:"blog-post single-post"},[(_vm.loading)?_c('div',{staticStyle:{"margin-top":"10%"}},[_c('div',{staticClass:"row m-0"},[_c('v-progress-circular',{staticStyle:{"margin":"auto"},attrs:{"indeterminate":"","color":"primary"}})],1),_c('div',{staticClass:"row m-0"},[_c('h4',{staticStyle:{"margin":"auto"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])])]):_vm._e(),_c('div',{staticClass:"post-content"},[_c('countries-flags',{attrs:{"countries":_vm.publication.countries && _vm.publication.countries.length > 0
                      ? _vm.publication.countries
                      : []}}),_c('h1',{staticStyle:{"font-size":"1.8rem"}},[_vm._v(_vm._s(_vm.publication.title))]),(_vm.publication.type !== 'events')?_c('ul',{staticClass:"post-meta my-2"},[(_vm.publication.date)?_c('li',[_vm._v(" "+_vm._s(new Date(_vm.publication.date).toLocaleDateString('es-VE', { year: 'numeric', month: 'long', day: 'numeric', }))+" ")]):_vm._e(),_c('li',[_vm._v(" "+_vm._s(_vm.publication.author)+" ")])]):_vm._e(),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[(_vm.publication.cover && _vm.publication.cover.route)?_c('img',{staticClass:"post-img img-fluid my-3",staticStyle:{"width":"70%"},attrs:{"src":_vm.publication.cover.route,"alt":_vm.publication.title}}):_vm._e()]),(_vm.publication.type === 'events')?_c('v-card-text',[(_vm.publication.address)?_c('span',[_c('strong',[_vm._v("Ubicación:")]),_vm._v(" "+_vm._s(_vm.publication.address))]):_vm._e(),(_vm.publication.address)?_c('br'):_vm._e(),(_vm.publication.dateRange > 0 && _vm.publication.startAt)?_c('span',[_c('strong',[_vm._v("Inicia:")]),_vm._v(" "+_vm._s(_vm.publication.startAt))]):_vm._e(),(_vm.publication.dateRange > 0 && _vm.publication.startAt)?_c('br'):_vm._e(),(_vm.publication.dateRange > 0 && _vm.publication.expired_at)?_c('span',[_c('strong',[_vm._v("Culmina:")]),_vm._v(" "+_vm._s(_vm.publication.endAt))]):_vm._e(),(_vm.publication.dateRange > 0 && _vm.publication.expired_at)?_c('br'):_vm._e(),(_vm.publication.dateRange == 0)?_c('span',[_c('strong',[_vm._v("Fecha:")]),_vm._v(" "+_vm._s(_vm.publication.startAt + ' . Único día'))]):_vm._e(),(_vm.publication.dateRange == 0)?_c('br'):_vm._e(),(_vm.publication.modality)?_c('span',[_c('strong',[_vm._v("Modalidad:")]),_vm._v(" "+_vm._s(_vm.publication.modalityText))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"post-text text-box",domProps:{"innerHTML":_vm._s(_vm.publication.content)}})],1)]),(
                _vm.publication.cta_link &&
                _vm.publication.cta_link !== '' &&
                _vm.publication.cta_text &&
                _vm.publication.cta_text !== ''
              )?_c('a',{staticClass:"cta__button",attrs:{"href":_vm.publication.cta_link,"target":"_blank","rel":"noopener"}},[_c('v-icon',{staticStyle:{"color":"#003a56"},attrs:{"dark":""}},[_vm._v(" mdi-cursor-default-click ")]),_vm._v(" "+_vm._s(_vm.publication.cta_text)+" ")],1):_vm._e()]),_c('div',{staticClass:"col-lg-3 col-md-4"},[_c('div',{staticClass:"sidebar right"},[_c('div',{staticClass:"widget margin-top-40"},[_c('h3',[_vm._v("Más populares")]),_c('ul',{staticClass:"widget-tabs"},_vm._l((_vm.items.slice(0, 5)),function(post){return _c('li',{key:post.id},[_c('div',{staticClass:"widget-content"},[_c('div',{staticClass:"widget-thumb"},[_c('a',{attrs:{"href":'/publicaciones/' + post.slug}},[_c('img',{staticClass:"img-fluid",attrs:{"alt":"","src":post.cover.route}})])]),_c('div',{staticClass:"widget-text"},[_c('a',{attrs:{"href":'/publicaciones/' + post.slug}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(post.title)+" ")])]),_c('span',[_vm._v(_vm._s(new Date(post.date).toLocaleDateString('es-VE', { year: 'numeric', month: 'long', day: 'numeric', })))])]),_c('div',{staticClass:"clearfix"})])])}),0)]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"margin-bottom-40"}),_c('ads-section-3',{staticStyle:{"padding":"0px"},attrs:{"ads":_vm.v2Ads}}),_c('social')],1)])])])]),_c('Whatsapp')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }